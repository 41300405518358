import RestApiImage from "assets/homepage/landing/buildingblocks/TriggerRestApi.svg";
import WebhookImage from "assets/homepage/landing/buildingblocks/TriggerWebhook.svg";
import ScheduleImage from "assets/homepage/landing/buildingblocks/TriggerScheduledJob.svg";
import Autoscaling from "assets/homepage/landing/buildingblocks/Autoscaling.png";
import OutputImage from "assets/homepage/landing/buildingblocks/DataOutputs.svg";
import VolumeImage from "assets/homepage/landing/buildingblocks/Volume.svg";
import SystemImage from "assets/homepage/landing/buildingblocks/System.svg";

import { IBuildingBlock } from "./BuildingBlock";

const VolumeExampleCode = `
from beam import Volume

# Mount a distributed storage volume
model_weights = Volume(
    name="model_models", 
    mount_path="./weights"
)`;

const OutputsExampleCode = `
from beam import Output

image = pipe( ... )
output = Output.from_pil_image(image).save()

# Generate a public URL for images generated
public_url = output.public_url()
`;

const QueueDepthCode = `
from beam import QueueDepthAutoscaler

# Scale out when queue size > 30 tasks
autoscaling_config = QueueDepthAutoscaler(
    tasks_per_container=30,
    max_containers=300,
)`;

const WorkersCode = `
from beam import endpoint

@endpoint(workers=5, on_start=load_models)
def inference():
    llm = vllm.LLM(model="facebook/opt-125m")
    return {"prediction": llm.generate("The future of AI is")[0].text}
`;

const RestApiExampleCode = `
from beam import endpoint

@endpoint(gpu="A100-40", keep_warm_seconds=60)
def inference():
    llm = vllm.LLM(model="facebook/opt-125m")
    return {"prediction": llm.generate("The future of AI is")[0].text}`;

const WebhookExampleCode = `
from beam import task_queue

@task_queue(gpu="A10G", callback_url="ngrok.io")
def transcribe():
    model = whisper.load_model("small")
    model.transcribe("./meeting-notes")`;

const RunExampleCode = `
from beam import function

@function(gpu="A100-40", memory="32Gi", cpu="16")
def fine_tune():
    model = AutoModelForSequenceClassification.from_pretrained()
    trainer = Trainer(model, train_dataset="./test")
    trainer.train()`;

const BuildingBlocks: IBuildingBlock[] = [
  {
    type: "Powerful Primitives",
    title: "Run Your Entire Data Science Stack",
    items: [
      {
        name: "Deploy a Serverless Inference API",
        description: `Deploy a serverless inference API with a single command. Your APIs come batteries-included with authentication, autoscaling, logging, and a full suite of metrics.`,
        illustration: {
          layout: "1",
          code: RestApiExampleCode,
          imageSrc: RestApiImage
        }
      },
      {
        name: "Run a Task Queue",
        description:
          "Deploy your functions to a Task Queue. Includes automated retries, callbacks on task completion, and APIs to query the status of your tasks.",
        illustration: {
          layout: "1",
          code: WebhookExampleCode,
          imageSrc: WebhookImage
        }
      },
      {
        name: "Train LLMs and Gen AI Models",
        description: "Train multi-billion parameter models at scale, with a single command.",
        illustration: {
          layout: "1",
          code: RunExampleCode,
          imageSrc: ScheduleImage
        }
      }
    ]
  },
  {
    type: "GPU Autoscaling",
    title: "Scale Out Workloads to Hundreds of GPUs",
    items: [
      {
        name: "Autoscale by Queue Depth",
        description: `We'll scale out your workloads across hundreds of machines. You define how you'd like us to scale, and we'll do it for you.`,
        illustration: {
          layout: "2",
          imageSrc: Autoscaling,
          code: QueueDepthCode
        }
      },
      {
        name: "Run Multiple Workers in the Same Container",
        description: "Scale your workloads vertically by running multiple inputs in the same container.",
        illustration: {
          layout: "2",
          imageSrc: Autoscaling,
          code: WorkersCode
        }
      }
    ]
  },
  {
    type: "Data Management",
    title: "Store Data in Globally Distributed Cloud Volumes",
    items: [
      {
        name: "Storage Volumes",
        description:
          "Access the files you need for training and inference, using highly-performant storage volumes mounted directly to your containers.",
        illustration: {
          layout: "1",
          code: VolumeExampleCode,
          imageSrc: VolumeImage
        }
      },
      {
        name: "Files and Model Artifacts",
        description:
          "Conveniently access anything — files, images, videos — created when your functions are run. Download pre-signed URLs from our APIs, or retrieve files from the web dashboard.",
        illustration: {
          layout: "1",
          code: OutputsExampleCode,
          imageSrc: OutputImage
        }
      }
    ],
    accordionHeight: 135
  }
];

export default BuildingBlocks;

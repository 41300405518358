import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import HomepageRoutes from "./HomepageRoutes";
import Header from "./components/Header";
import Footer from "./components/Footer/index";

const Homepage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container ref={containerRef}>
      <Helmet>
        <title>Open Source Cloud Functions for Data Teams • Beam</title>
      </Helmet>
      <Header />
      <Main>
        <HomepageRoutes />
      </Main>
      <Footer />
    </Container>
  );
};

export default Homepage;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.colors.gray1};

  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const Main = styled.main``;
